html {
  min-height: 100%;
  height: auto;
  background: linear-gradient(180deg, #9664D1 0%, #3274BA 100%);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

body {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background: transparent; 
}

.form-page-container {
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 40px;
  max-width: 800px;
  width: 95%;
  margin: 20px auto; 
  overflow: hidden;
  display: flex; /* Establish a flex container */
  flex-direction: column; /* Stack children vertically */
  justify-content: space-between; /* Create space between content and buttons */
  padding-bottom: 20px;  
}

h3 {
  color: #333;
  font-size: 1.25rem;
  margin-bottom: 1em;
}

.form-check {
  margin: 10px 0;
  position: relative;
  padding-left: 60px;
}

.form-check-input {
  width: 20px;
  height: 20px;
  margin-top: 0;
  margin-right: 10px;
  vertical-align: middle;
  border-radius: 50%;
  border: 2px solid #9c9a9a;
  appearance: none; 
  padding: 10px;
  cursor: pointer;
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
}

.form-check-input:checked {
  background-color: #3274BA;
  border-color: #3274BA;
}

.form-check-label {
  font-size: 1rem;
  color: #333;
  margin-bottom: 0;
  user-select: none;
}

/* Text Input Styling */
.form-control {
  border-radius: 20px;
  border: 1px solid #ccc;
  padding: 10px 30px 10px 10px; 
  font-size: 1rem;
  margin-bottom: 1em;
  display: flex; 
  align-items: center; 
}

.form-navigation-buttons {
  display: flex; /* Use flexbox for positioning the buttons */
  justify-content: flex-end; /* Align buttons to the right */
  padding-right: 0px; 
  margin-top: 50px;  
  position: center;
  align-items: center;
  text-align: center;
  white-space: nowrap; /* Prevents the text from wrapping onto the next line */
  border-radius: 10px;
}

.btn {
  background-color: #3274BA;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  margin-left: 10px; 
  width: 150px; 
}

.btn:hover {
  background-color: #285a8e;
}

.btn:disabled {
  background-color: #ccc;
}

.form-check-input {
  cursor: pointer;
}

.form-control {
  border-radius: 20px;
  border: 1px solid #ccc;
  padding: 10px;
}



.form-record-container {
  display: flex; 
  /* flex-direction: column; */
  align-items: center; 
  background-color: #f0f0f0; 
  border-radius: 30px; 
  padding: 7px; 
  margin-bottom: 1em;
}

.form-record-input {
  border: none; 
  outline: none; 
  padding: 10px; 
  font-size: 1rem;
  flex: 1;  
  border-radius: 20px;  
  background-color: #fff; 
  resize: none; 
  overflow: hidden; 
  min-height: 40px; 
}

.form-record-button {
  width: 34px; 
  height: 34px;
  border-radius: 50%;
  background-color: rgb(161, 160, 160);
  border: none;
  cursor: pointer;
  margin-left: 10px; 
  transition: background-color 0.2s ease-in-out, transform 0.2s ease-in-out;
}

.form-record-button:hover {
  background-color: rgba(0, 0, 0, 0.1); 
}

.form-record-button.recording {
  background-color: #ee4858; 
  transform: scale(1.1); 
}

.form-record-button .fa-microphone {
  font-size: 18px; 
  color: #fff; 
}

.assessment-duration-notice{
  text-align: center;
  color: #ffffff; 
  font-size: 1.1rem; 
  margin-top: 20px; 
  margin-bottom: 20px;
  line-height: 1.5;
}


@media (min-height: 100%) {
  html {
    background-size: cover;
  }
}

.status-bar-container {
  width: 100%;
  height: 25px; /* Increased height for better visibility */
  background-color: #f0f0f0;
  border-radius: 10px; /* More pronounced rounded corners */
  overflow: hidden;
  margin-bottom: 20px;
  margin-top: 20px;
  position: relative;
  display: flex;
  align-items: center;
}

.status-bar {
  width: 0%;
  height: 100%;
  background: linear-gradient(90deg, #3274BA 0%, #9664D1 100%);
  transition: width 0.5s ease-in-out;
  border-radius: 10px; /* Ensure the bar itself also has rounded corners */
  align-items: center; /* Center text vertically */
}

.progress-percentage {
  position: absolute;
  color: #000; /* Black text */
  font-size: 0.9rem; /* Slightly larger font size */
  width: 100%;
  text-align: center; /* Center text horizontally */
}

.save-and-exit-button {
  display: flex; /* Use flexbox for layout */
  justify-content: center; /* Center the button horizontally */
  align-items: center; /* Center the button vertically */
  margin-top: 50px; /* Adjust spacing as needed */
  width: 100%; /* Ensure the div takes full width to center the button properly */
}



@media (max-width: 768px) {
  .form-page-container {
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 40px;
    max-width: 800px;
    width: 95%;
    margin: 20px auto; 
    overflow: hidden;
    display: flex; 
    flex-direction: column; 
    justify-content: flex-start; 
    padding-bottom: 20px;
    position: relative; 
    min-height: 400px; 
  }

  .form-navigation-buttons {
    display: flex;
    justify-content: flex-end; 
    margin-top: auto; 
    align-items: center;
    text-align: center;
    white-space: nowrap; 
    border-radius: 10px;
    width: 150px;
    position: absolute; 
    bottom: 20px; 
    right: 20px; 
  }

  .btn {
    width: 150px; 
    margin-top: 20px; 
    font-size: 14px; 
  }

  .form-check-label {
    font-size: 14px; 
  }
  
  .status-bar-container {
    position: absolute;
    bottom: 20px; /* Position the progress bar at the bottom of the form container */
    left: 20px;
    right: 20px; /* Maintain some padding on the sides */
    height: 25px; /* Maintain the height for better visibility */
    margin-top: 0; /* Remove any additional top margin */
  }

  .progress-percentage {
    top: 50%; /* Center the text vertically */
    transform: translateY(-50%); /* Adjust for vertical centering */
  }

}