body, html {
    margin: 0;
    background: linear-gradient(180deg, #9664D1 0%, #3274BA 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .assessment-container {
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 30px; /* Increased padding for wider inputs */
    max-width: 800px; /* Increased max-width for wider inputs */
    width: 95%;
    margin: 20px;
  }
  
  h3 {
    color: #333;
    font-size: 1.25rem;
    margin-bottom: 1em;
  }
  
  .assessment-btn {
    background-color: #3274BA;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    margin-left: 10px; 
  }
  
  .assessment-btn:hover {
    background-color: #285a8e;
  }
  
  .assessment-btn:disabled {
    background-color: #ccc;
  }
  
  

  
  @media (max-width: 768px) {
    .assessment-container {
      padding: 20px;
      margin: 10px;
    }
  
    .btn {
      width: 100%;
      margin-bottom: 10px;
    }
  }
  

  
