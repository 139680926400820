/* Global Styles */
body, html {
  margin: 0;
  min-height: 100%; 
  background: linear-gradient(180deg, #9664D1 0%, #3274BA 100%);
  font-family: 'Poppins', sans-serif;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover; /* Ensure that the background covers the entire content */
}
  
/* Home Page Container Styles */
.home-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: inherit;
}
  
.home-page-title {
  color: white;
  margin: 0;
  padding: 15px;
  font-size: 3rem;
  font-weight: 500;
}
  
.home-page-subtitle {
  color: white;
  margin: 0;
  padding-bottom: 25px;
  font-weight: 400;
  font-size: 1.5rem;
}
  
/* Input-Button Section Styles */
.input-button-section {
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 24px 16px;
  gap: 20px;
  width: 90%;
  max-width: 472px;
}
  
.input-button-section .pin-input-field {
  width: 100%;
  padding: 15px 20px;
  font-size: 1rem;
  border: 2px solid #D1D5DB;
  border-radius: 10px;
}
  
.input-button-section .start-assessment-btn {
  width: 95%;
  padding: 15px 20px;
  font-size: 1rem;
  border-radius: 20px;
  background-color: #3274BA;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}
  
.input-button-section .start-assessment-btn:hover {
  background-color: #9664D1;
}
  
/* Error Message Styles */
.input-button-section .pin-error-msg {
  color: #DC2626;
  font-size: 0.875rem;
  text-align: center;
  padding-top: 10px; /* Adjusted to give space above the error message */
}
  
/* Responsive Design Adjustments */
@media (max-width: 768px) {
  .home-page-title {
    font-size: 2rem; /* Smaller font size for smaller screens */
  }
  
  .home-page-subtitle {
    font-size: 1.2rem; /* Smaller font size for smaller screens */
  }
  
  .input-button-section {
    max-width: 90%; /* Adjusting max-width for smaller screens */
  }
}
