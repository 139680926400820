/* General styling for the form container */
.form-container {
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 40px;
    max-width: 800px;
    width: 95%;
    margin: 20px auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  /* Styling for each form field */
  .form-field {
    margin-bottom: 20px;
  }
  
  /* Label styling */
  .form-label {
    display: block;
    margin-bottom: 5px;
    color: #333;
    font-size: 1rem;
  }
  
  /* Input and select box styling */
  .form-input, .form-select {
    width: 100%;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid #ccc;
    font-size: 1rem;
  }
  
  /* Button styling */
  .form-button {
    background-color: #3274BA;
    color: white;
    border: none;
    margin-top: 20px;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    width: 100%;
  }
  
  .form-button:hover {
    background-color: #285a8e;
  }
  
  .form-message {
    margin: 20px auto 40px;
    font-family: 'Gill Sans', Gill Sans MT, 'Calibri', 'Trebuchet MS', 'sans-serif';
    padding: 10px;
    color: #fefbfb;
    font-size: 1.4rem;
    text-align: center;
    max-width: 800px;
    width: 95%;
}

@media (min-height: 100%) {
  html {
    background-size: cover;
  }
}


@media (max-width: 768px) {
  .form-container {
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 40px;
    max-width: 800px;
    width: 95%;
    margin: 20px auto; 
    overflow: hidden;
    display: flex; 
    flex-direction: column; 
    justify-content: flex-start; 
    padding-bottom: 20px;
    position: relative; 
    min-height: 400px; 
  }

  .form-navigation-buttons {
    display: flex;
    justify-content: flex-end; 
    margin-top: auto; 
    align-items: center;
    text-align: center;
    white-space: nowrap; 
    border-radius: 10px;
    width: 150px;
    position: absolute; 
    bottom: 20px; 
    right: 20px; 
  }

  .btn {
    width: 150px; 
    margin-top: 20px; 
    font-size: 14px; 
  }

  .form-check-label {
    font-size: 14px; 
  }
}


